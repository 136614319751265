@font-face {
  font-family: "QuintUpper";
  src: local("QuintUpper"),
  url("./fonts/quint-uppercase-font.otf");
  font-weight: bold;
}
@font-face {
  font-family: "QuintLower";
  src: local("QuintLower"),
  url("./fonts/quint_lower.ttf");
  font-weight: bold;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

